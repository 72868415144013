import { Alert, Button, Typography } from '@mui/material';
import { createRef, useCallback, useEffect, useState } from 'react';
import { Operator, RemoteFileFieldOptions } from "@crochik/pi-api";
import { parseResponseError } from 'src/pi/api/Client';
import DataService, { IReferenceValue } from 'src/pi/services/DataService';
import { FileDropTarget } from 'src/pi/ui/FileDropTarget';
import { Form } from 'src/pi/ui/Form';
import { Loading } from '../../Loading';
import { FieldProps } from "../FieldProps";
import App from "../../../../application/App";

interface IProps extends FieldProps {
    form?: Form;
}

interface IRemoteFile {
    id: string;
}

export function RemoteFileField(props: IProps) {
    const { field, value, form, onChange } = props;
    const options = field.options as RemoteFileFieldOptions;
    const [file, setFile] = useState<File>();
    const [folderId, setFolderId] = useState<string>();
    const [error, setError] = useState<string>();
    const [contentType, setContentType] = useState<string>();
    const [uploadUrl, setUploadUrl] = useState<string>();
    const [isUploading, setUploading] = useState<boolean>(false);
    const [currentFile, setCurrentFile] = useState<IReferenceValue>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isEditing, setEditing] = useState<boolean>(false);
    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
        const prefix = options.url;
        if (!prefix || !prefix.startsWith("/files/v1/Folder")) {
            setError("Invalid configuration");
            return;
        }

        setUploadUrl(`${prefix}/Upload`);
    }, [options?.url]);

    useEffect(() => {
        if (!options?.criteria) {
            setError('Mising criteria');
            return;
        }

        const parentCriteria = options.criteria.find(x => x.fieldName === "ParentId");
        if (!parentCriteria?.value) {
            setError('Missing parent folder');
            return;
        }
        const contentTypeCriteria = options.criteria.find(x => x.fieldName === "ContentType");
        if (!contentTypeCriteria?.value) {
            setError('Missing content type');
            return;
        }
        setFolderId(parentCriteria.value);
        setContentType(contentTypeCriteria.value);

    }, [options?.criteria])

    useEffect(() => {
        const fetchCurrentValue = async (value?: string) => {
            setCurrentFile(undefined);

            if (!value) {
                setLoading(false);
                return;
            }

            const criteria = [
                ...(options?.criteria ?? []),
                {
                    fieldName: "#id",
                    operator: Operator.Eq,
                    value,
                },
            ];

            var rows = await DataService()
                .lookupAsync("RemoteFile", {
                    criteria,
                    lookupField: options.foreignFieldName,
                })
                .catch(reason => {
                    parseResponseError(reason).then(setError);
                });

            setLoading(false);

            if (!rows || rows.length !== 1) {
                setError("Failed to load");
                return;
            }

            setCurrentFile(rows[0]);
        }

        fetchCurrentValue(value);
    }, [value, options?.criteria, options.foreignFieldName]);

    const onReload = useCallback(() => {
        form?.reloadAsync();
    }, [form]);

    const onChangeRemoteFile = useCallback(
        (value?: string) => {
            onChange?.(field, value);
        },
        [onChange, field]
    );

    const upload = () => {
        if (!file || !uploadUrl) return;

        var formData = new FormData();
        formData.append("file", file);

        const client = App().apiClient;
        if (!client) return;

        setUploading(true);
        client
            .postForm<IRemoteFile>(uploadUrl, formData)
            .then(result => {
                cancel();
                if (result.id) {
                    onChangeRemoteFile(result.id);
                }
            })
            .catch(reason => {
                parseResponseError(reason)
                    .then(e => {
                        setError(e);
                        cancel();
                    })
            });
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        for (let c = 0; c < e.target.files.length; c++) {
            if (e.target.files[c].type === contentType) {
                loadFile(e.target.files[c]);
                return;
            }
        }

        cancel();
    };

    const loadFiles = async (files: File[]) => {
        for (let c = 0; c < files.length; c++) {
            if (files[c].type === contentType) {
                loadFile(files[c]);
                return;
            }
        }

        cancel();
    };

    const loadFile = async (file: File) => {
        setFile(file);
    };

    const cancel = () => {
        setEditing(false);
        setFile(undefined);
        if (inputRef.current) inputRef.current.value = "";
    }

    const onStartEditing = () => {
        setEditing(true);
    }

    const onCancelEditing = () => {
        setEditing(false);
    }

    if (error) {
        // error
        return <Alert severity="error">{error}</Alert>
    }

    if (file) {
        // ready to upload
        return (
            <div style={{ padding: '8px 8px 8px 4px' }}>
                {file.name}<br />
                {file.type}<br />
                {file.size}
                <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                    <Button key="cancel" variant="outlined" onClick={cancel} disabled={isUploading}>
                        <span>Cancel</span>
                    </Button>
                    <Button key="upload" variant="outlined" onClick={upload} disabled={isUploading}>
                        <span>Upload</span>
                    </Button>
                </div>
                {isUploading && <Loading />}
            </div>
        )
    }

    if (isLoading) {
        return (<div style={{ padding: '6px' }}><Loading /></div>);
    }

    // TODO: should it have a way to select existing (e.g. behave as a normal ReferenceField?)
    // ...

    // TODO: CHECK ACTIONS TO DECIDE IF IT CAN UPLOAD OR NOT
    // ...

    if (currentFile?.value) {
        // there is a file
        return (
            <FileDropTarget onFilesDropped={loadFiles} key="dropTarget">
                <div style={{ padding: '6px' }}>
                    <Typography>{currentFile?.value}</Typography>

                    {
                        isEditing ? (
                            <div style={{ paddingTop: '8px', paddingBottom: '8px', display: 'flex' }}>
                                <div key="upload" style={{ border: '1px solid black', padding: '8px', marginRight: '16px' }}>
                                    <input ref={inputRef} type="file" onChange={onSelectFile} accept={contentType} />
                                </div>
                                <Button key="cancel" variant="outlined" onClick={onCancelEditing}>
                                    <span>Cancel</span>
                                </Button>
                            </div>

                        ) : (
                            <div style={{ paddingTop: '8px', paddingBottom: '8px', display: 'flex' }}>
                                <Button key="edit" variant="outlined" onClick={onStartEditing}>
                                    <span>Replace</span>
                                </Button>
                                <Button key="view" variant="outlined" disabled>
                                    <span>View</span>
                                </Button>
                            </div>
                        )
                    }
                </div>
            </FileDropTarget>
        )
    }

    // editing
    return (
        <FileDropTarget onFilesDropped={loadFiles} key="dropTarget">
            <div style={{ padding: '6px' }}>
                <input ref={inputRef} type="file" onChange={onSelectFile} accept={contentType} />
            </div>
        </FileDropTarget >
    )
}