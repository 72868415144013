import { action, computed, observable, reaction, runInAction } from "mobx";
// import * as ReactGA from "react-ga";
import * as api from "@crochik/pi-api";
import { ISession, Session } from "src/app/Session";
import { Client } from "../api/Client";

import { IAction } from "../context/Actions";
import { Default } from "../context/AppContext";
import { IApp } from "../context/IApp";
import { IDataView } from "../context/IDataView";
import { Action, IForm } from "../context/IForm";
import { IMenu } from "../context/IMenu";
import { IGridPage, IPage } from "../context/IPage";

import DataService, { IDataFormActionRequest, IDataFormActionResponse } from "../services/DataService";
import DialogService from "../services/Dialog";

import { URI } from "../api/URI";
import { DataView } from "../ui/DataView";
import { Form } from "../ui/Form";
import { Menu } from "./Menu";
import { createPage, CustomPage2, ExternalPage, GridPage, Page } from "./Page";

export const Breakpoints: api.ScreenBreakpoint[] = [api.ScreenBreakpoint.Xs, api.ScreenBreakpoint.Sm, api.ScreenBreakpoint.Md, api.ScreenBreakpoint.Lg, api.ScreenBreakpoint.Xl]; // "xs", "sm", "md", "lg", "xl"

export interface ParsedPageUrl {
    page?: Page;
    pageName: string;
    args: any;
    url: string;
    id?: string | null;
}

export interface IFormDialog {
    key?: string;
    form: Form;

    // url?: string;
    // allow the dialog caller to intercept action
    // returning true will stop default behavior
    // when the dialog is closed the action.name=="#closed"
    onActionAsync?: (dialog: IFormDialog, action?: Action, lastResult?: IDataFormActionResponse) => Promise<boolean>;
}

interface Location {
    page: string;
    path: string;
    args: any[];
    previous?: Location;
    menu?: string;
}

interface State {
    // menu?: Menu;
    showTitleBar: boolean;
    showMenu: boolean;
    location?: Location;
    innerHeight: number;
    innerWidth: number;
    breakpoint: api.ScreenBreakpoint;
    alwaysCollpseDrawer: boolean;
    title?: string;
    pageMenu?: IMenu;
    defaultMenuName?: string;
    zoom?: number;

    // dialogForm?: Form;
    // dialogFormPath?: string;
    formDialogs: IFormDialog[];
}

export interface IClientConfiguration {
    basePath: string;
    accessToken: string;
}

export class App {
    private _state: State;
    private _data: IApp;
    // private _apiConfig?: api.Configuration;
    private _accessToken?: string;
    private _basePath?: string;

    constructor(app: IApp) {
        this._data = app;

        var state: State = {
            alwaysCollpseDrawer: app.alwaysCollpseDrawer ? true : false,
            breakpoint: api.ScreenBreakpoint.Sm,
            formDialogs: [],
            innerHeight: 0,
            innerWidth: 0,
            location: undefined,
            pageMenu: undefined,
            showMenu: !app.hideMenu,
            showTitleBar: !app.hideTitlebar
        };

        this._state = observable(state);

        Default.ui.set("app", this);
        Default.state.set("app", this._state);

        if (window) {
            window.addEventListener("load", this.resize);
            window.addEventListener("resize", this.resize);
            this.resize();
        }

        reaction(
            () => this.path,
            () => {
                if (this.menu) this.menu.open = false;
            }
        );

        this.initHistory();

        // // init GA
        // if (app.ga) {
        //     ReactGA.initialize(app.ga);
        //     this.logPageview(window.location.pathname); //  + window.location.search
        // }
    }

    @computed
    get session(): ISession {
        return Session.get();
    }

    @computed
    get innerHeight(): number {
        return this._state.innerHeight;
    }

    @computed
    get innerWidth(): number {
        return this._state.innerWidth;
    }

    @computed
    get breakpoint(): api.ScreenBreakpoint {
        return this._state.breakpoint;
    }

    get name(): string {
        return this._data.name;
    }

    get authorizationHeader() {
        return `Bearer ${this._accessToken}`;
    }

    // openapi generator assumes the accessToken has the Bearer prefix
    get apiConfig() {
        return new api.Configuration({
            basePath: this._basePath!,
            accessToken: () => this.authorizationHeader,
        });
    }

    get clientConfiguration(): IClientConfiguration {
        return {
            basePath: this._basePath!,
            accessToken: this._accessToken!
        };
    }

    get apiClient(): Client | undefined {
        const config = this.clientConfiguration;
        if (!config || !config.accessToken || !config.basePath) {
            return undefined;
        }

        const client = new Client(config.basePath, config.accessToken);
        return client;
    }

    @computed
    get dialogs(): IFormDialog[] {
        return this._state.formDialogs ?? [];
    }

    @computed
    get formDialog(): IFormDialog | undefined {
        const formDialog = this._state.formDialogs.length > 0 ? this._state.formDialogs[this._state.formDialogs.length - 1] : undefined;
        return formDialog;
    }

    @computed
    get menu(): Menu | undefined {
        if (!this._state.location?.menu || this._state.location?.menu === "") return undefined;

        const menu = Default.ui.get(this._state.location?.menu, "menu");
        if (!menu) {
            console.debug("Menu not found in cache", this._state.location);
        }

        return menu;
    }

    @computed
    get page(): Page | undefined {
        if (!this._state.location) return undefined;

        const page = Page.get(this._state.location.page);
        if (!page) {
            console.debug("Page not found in cache", this._state.location);
        }

        return page;
    }

    @computed
    get pageMenu(): IMenu | undefined {
        if (this._state.pageMenu) return this._state.pageMenu;
        return this.page && this.page.menu ? this.page.menu : undefined;
    }

    set pageMenu(value: IMenu | undefined) {
        this._state.pageMenu = value;
    }

    @computed
    get path(): string | undefined {
        return this._state.location ? this._state.location.path : undefined;
    }

    @computed
    get args(): any[] {
        return this._state.location ? this._state.location.args : [];
    }

    @computed
    get showMenu(): boolean {
        if (!this._state.showMenu) return false;
        if (this.page?._data.hideAppBar) return false;
        if (!this.showTitleBar) return false;

        const menu = this.menu;
        return menu?.items != null && menu.items.length > 0;
    }

    set showMenu(value: boolean) {
        this._state.showMenu = value;
    }

    @computed
    get showTitleBar(): boolean {
        if (this.page?._data.hideAppBar) return false;

        var name = this.page ? this.page.label : this.name;
        if (!name) return false;
        return this._state.showTitleBar;
    }

    set showTitleBar(value: boolean) {
        this._state.showTitleBar = value;
    }

    @computed
    get title(): string | undefined {
        if (this._state.title) return this._state.title;
        return this.page ? this.page.label : this.name;
    }

    set title(value: string | undefined) {
        this._state.title = value;
    }

    @computed
    get alwaysCollpaseDrawer(): boolean {
        return this._state.alwaysCollpseDrawer;
    }

    set alwaysCollpaseDrawer(value: boolean) {
        this._state.alwaysCollpseDrawer = value;
    }

    static bindAction(action: string, funct: Function) {
        Default.actions.set(action, funct, "app");
    }

    resetConfig() {
        this._accessToken = undefined;
        this._basePath = undefined;
    }

    initConfig(basePath: string, accessToken: string) {
        this._accessToken = accessToken;
        this._basePath = basePath;
    }

    updateAccessToken(accessToken: string) {
        this._accessToken = accessToken;
    }

    getBreakpointFromWidth(width: number): api.ScreenBreakpoint {
        // xs, extra-small: 0px
        // sm, small: 600px
        // md, medium: 900px
        // lg, large: 1200px
        // xl, extra-large: 1536px
        if (width >= 1536) {
            return api.ScreenBreakpoint.Xl;
        } else if (width >= 1200) {
            return api.ScreenBreakpoint.Lg;
        } else if (width >= 900) {
            return api.ScreenBreakpoint.Md;
        } else if (width >= 600) {
            return api.ScreenBreakpoint.Sm;
        }
        return api.ScreenBreakpoint.Xs;
    }

    resize = () => {
        console.log("on resize", window.innerWidth, window.innerHeight);

        const mobile = window.innerWidth < 600;
        // const zoom = this._state.zoom ?? (mobile ? 1 : .8);
        const zoom = 1;
        const innerHeight = window.innerHeight / zoom;
        const innerWidth = window.innerWidth / zoom;
        const breakpoint = this.getBreakpointFromWidth(innerWidth);

        if (document?.documentElement) {
            document.documentElement.style.setProperty("--vh", innerHeight / 100 + "px");
            // document.documentElement.style["zoom"] = `${zoom*100}%`;
        }

        runInAction(() => {
            Object.assign(this._state, {
                zoom,
                innerHeight,
                innerWidth,
                breakpoint
            });
        });
    };

    // @action
    async setDefaultMenuNameAsync(name?: string) {
        this._state.defaultMenuName = name;

        if (this._state.defaultMenuName) {
            await this.loadMenuAsync(this._state.defaultMenuName);
        }
    }

    logPageview(path: string) {
        if (path && !path.startsWith("/")) {
            path = `/${path}`;
        }

        // if (this._data.ga) {
        //     ReactGA.pageview(path);
        // }
    }

    logEvent(args: {
        category: string;
        action: string;
        label?: string;
        value?: number;
        nonInteraction?: boolean;
        transport?: string
    }) {
        if (this._data.ga) {
            // ReactGA.event(args);
        }
    }

    @action
    navigate(location?: Location) {
        if (!location) {
            // reset 
            this._state.pageMenu = undefined;
            this._state.location = location;
            this._state.title = undefined;
            this._state.formDialogs = [];
            return;
        }

        location.previous = this._state.location;

        this._state.pageMenu = undefined;
        this._state.location = location;
        this._state.title = undefined;
        this._state.formDialogs = [];

        this.logPageview(location.path);
    }

    initHistory() {
        if (this._data.disableHistory) {
            console.debug("history is disabled");
            return;
        }

        window.onpopstate = (e: PopStateEvent) => {
            if (e.state) {
                this.navigate(e.state as Location);
            } else {
                var numberOfEntries = window.history.length;
                console.log(`no state, probably landing page: ${numberOfEntries}`);
            }
        };
    }

    async loadMenuAsync(name: string): Promise<Menu | undefined> {
        const loaded = await new api.AppConfigApi(this.apiConfig)
            .appConfigGetMenuFromConfig(name)
            .catch((reason) => {
                console.error("failed to load form", name, reason);
                return undefined;
            });

        if (!loaded) return loaded;

        const menu = loaded as IMenu;
        menu.name = name;

        return Menu.create(menu);
    }

    async loadFormAsync(nameOrUri: string): Promise<Form | undefined> {
        // console.log("app::getForm", nameOrUrl);

        if (nameOrUri.startsWith("/")) {
            // console.log('getForm using path', nameOrUri);
            const dataForm = await DataService()
                .dataFormAsync(nameOrUri)
                .catch((response) => {
                    console.error(`Failed to get form: ${nameOrUri}`, response);
                });

            return dataForm ? Form.create(dataForm, nameOrUri) : undefined;
        }

        // console.log('Try to find form in cache', nameOrUri);
        const form = Form.get(nameOrUri);
        if (form) {
            // console.log('Found form in cache', nameOrUri);
            return form;
        }

        // console.log('get app form', nameOrUri);
        const loaded = await new api.AppConfigApi(this.apiConfig)
            .appConfigGetFormFromConfig(nameOrUri)
            .catch((reason) => {
                console.error("failed to load form", nameOrUri, reason);
                return undefined;
            });

        if (loaded) {
            return Form.create(loaded as unknown as IForm, null); // ???
        }

        return undefined;
    }

    async loadDataViewAsync(name: string): Promise<DataView | undefined> {
        // const dv = DataView.get(name);
        // if (dv) return dv;

        const loaded = await new api.AppConfigApi(this.apiConfig).appConfigGetDataViewFromConfig(name).catch((reason) => {
            console.error("failed to load dataview", name, reason);
            return undefined;
        });

        if (loaded) {
            return DataView.create(loaded as any as IDataView);
        }

        return undefined;
    }

    public async pasePageUrlAsync(nameOrUri: string, args?: any): Promise<ParsedPageUrl | null> {
        // magic url or page://
        // page name
        const parsed = this.upgradePageUrl(nameOrUri);
        if (!parsed) return null;

        await this.loadPage(parsed);
        if (!parsed.page) {
            parsed.page = Page.get(parsed.pageName);
            if (!parsed.page) return null;
        }

        if (parsed.page instanceof CustomPage2) {
            // custom page2, always override args
            if (!!parsed.args) args = [parsed.args];

            if (nameOrUri.indexOf("?") > 0) {
                // override args with url parameters
                try {
                    // replace args with args from query
                    const uri = new URI(nameOrUri);
                    const props: { [name: string]: any } = {};
                    uri.searchParams.forEach((v, k) => (props[k] = v));
                    args = [props];
                } catch (e) {
                    console.error(`failed trying to parse page url: "${nameOrUri}"`, e);
                    return null;
                }
            }
        } else {
            // old behavior so it can use the DetailViewArgs
            // other pages, if other args were provided do not change
            if (!!parsed.args && (!args || args.length < 2)) args = [parsed.args];
        }

        parsed.args = args;

        return parsed;
    }

    // @action
    openInNewTab(path?: string): boolean {
        if (!path) return true;

        const index = path.indexOf(":");
        if (index > 0) {
            console.log("open in new tab", path);

            // url
            try {
                var url = new URI(path);
                switch (url.scheme) {
                    case "waze:":
                    case "mailto:":
                    case "tel:":
                    case "openphone:":
                    case "sms:":
                    case "http:":
                    case "https:":
                        this.launchInNewTab(path);
                        return true;
                    // case "dataform:" // will load on top of waiting page
                    // case "dataview": // may work
                    case "page:":
                        this.launchInNewTab(`${document.location.pathname}?${path}`);
                        return true;
                }
            } catch (e) {
                console.error("failed to parse url", e);
            }

            return false;
        }

        const oldPage = this.upgradePageUrl(path);
        if (!oldPage) {
            console.error(`Failed to parse page url: ${path}`);
            return true;
        }

        this.launchInNewTab(`${document.location.pathname}?${oldPage.url}`);

        return true;
    }

    cleanUpSMSUri(uri: URI): string {
        if (uri.path.startsWith("/")) uri.path = uri.path.substring(1);
        uri.path = uri.path.replaceAll(/\s/g, "");
        const body = uri.getParameter("body");

        return `sms:${uri.path}?body=${body}`;
    }

    cleanUpPhoneUri(uri: URI): string {
        if (uri.path.startsWith("/")) uri.path = uri.path.substring(1);
        uri.path = uri.path.replaceAll(/\s/g, "");

        const query = uri.query;
        return `${uri.scheme}:${uri.path}${query ?? ""}`;
    }

    cleanUpOpenPhoneUri(uri: URI): string {
        const query = uri.query;
        return `${uri.scheme}//${uri.path}${query ?? ""}`;
    }

    createHrefAndClickIt(url: string) {
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    launchInNewTab(url: string) {
        const uri = new URI(url);

        switch (uri.scheme) {
            case "tel:":
                url = this.cleanUpPhoneUri(uri);
                break;

            case "openphone:":
                url = this.cleanUpOpenPhoneUri(uri);
                break;

            case "sms:":
                url = this.cleanUpSMSUri(uri);
                break;
        }

        switch (uri.scheme) {
            case "mailto:":
            case "tel:":
            case "openphone:":
            case "sms:":
            case "waze:":
                this.createHrefAndClickIt(url);
                return;
        }

        const w = window.open?.(url, "_blank");
        if (!w) {
            console.error("failed to open new window");
            // window.location.href = url;
        }
    }

    // @action
    async selectPageAsync(nameOrUri?: string, ...args: any[]): Promise<boolean> {
        // alert(`selectPage: ${nameOrUri}`);

        if (!nameOrUri) {
            this.navigate();
            return true;
        }

        var found: Page | undefined;

        const eqIndex = nameOrUri.indexOf("=");
        if (eqIndex > 0 && (!args || args.length < 1)) {
            // parse id from url (ignore any other arguments for now)
            const parts = nameOrUri.substring(eqIndex + 1).split("&");
            args = [{ id: parts[0] }];
        }

        if (URI.isUri(nameOrUri)) {
            // uri (instead of page)
            var uri = new URI(nameOrUri);
            const pathAndQuery = uri.getPathAndQuery();

            switch (uri.scheme) {
                case "datagrid:": {
                    // TODO: check url fragment for name?
                    // ...
                    const data: IGridPage = {
                        name: nameOrUri,
                        label: args.length === 1 && args[0].label ? args[0].label : "Loading...",
                        grid: nameOrUri.substr(10),
                        t: "GridPage"
                    };
                    found = new GridPage(data);
                    break;
                }

                case "dataform:":
                    await this.dataFormAsync(pathAndQuery, uri.searchParams.get("id") ?? undefined);
                    return true;

                case "datafile:":
                    await this.dataFileActionAsync(pathAndQuery, "file");
                    return true;

                case "url:":
                    this.launchInNewTab(nameOrUri);
                    return false;

                case "page:":
                    break;

                case "waze:":
                case "mailto:":
                case "tel:":
                case "openphone:":
                case "sms:":
                case "callto:":
                case "http:":
                case "https:":
                    console.log("Open in new tab", nameOrUri);
                    this.openInNewTab(nameOrUri);
                    return true;

                case "action:":
                    this.dataFormActionAsync(pathAndQuery, { name: uri.searchParams.get("action") ?? "Default" }, {});
                    return true;

                default:
                    console.log(`${nameOrUri}: unexpected uri`);
                    return false;
            }
        }

        if (!found) {
            // magic url or page://
            // page name
            const parsed = await this.pasePageUrlAsync(nameOrUri, args);
            if (!parsed || !parsed.page) {
                console.log(`${nameOrUri}: Page not found`);
                return false;
            }

            found = parsed.page;
            args = parsed.args;
        }

        // TODO: currently if the page name is the same it will not trigger
        // a change in page or re-render :(
        // ...

        const newLocation: Location = {
            page: found.name,
            path: nameOrUri,
            menu: found.appMenu ?? this._state.location?.menu ?? this._state.defaultMenuName,
            args
        };

        if (found instanceof ExternalPage) {
            if (found.openInNewWindow && found.data?.url) {
                this.launchInNewTab(found.data.url);
                return false;
            }
        }

        if (!this._data.disableHistory) {
            const hash = btoa(nameOrUri).replace(/=/g, "");
            window.history.pushState(newLocation, "", `./index.html#${hash}`);
        }

        console.debug(`Navigate to ${nameOrUri} -> ${found.name}`);
        this.navigate(newLocation);

        return true;
    }

    async dataFormActionAsync(path: string, action: Action, parameters: object): Promise<IDataFormActionResponse> {
        if (!action.name) {
            console.error("missing action name");
            return {
                success: false,
                message: "Missing Action",
                action: "[Missing]"
            };
        }

        const request: IDataFormActionRequest = {
            action: action.name,
            parameters,
            selectedIds: [] // this.selectedIds
        };

        if (path && path.indexOf("{{") >= 0 && parameters) {
            Object.keys(parameters).forEach(prop => {
                const value = parameters[prop];
                if (!value) return;

                path = path.replaceAll(`{{${prop}}}`, value);
            });
        }

        const result = await DataService().dataFormActionAsync(path, request);

        if (!result.success) {
            // show error
            DialogService.error({
                title: "Error",
                message: result.message || "Unknown Error"
            });

            return result;
        }

        if (result.message) {
            DialogService.inform({
                title: "Success",
                message: result.message
            });
        }

        if (result.nextUrl && !result.nextUrl.startsWith("#")) {
            await this.executeAsync(result.nextUrl);

            return {
                action: result.action,
                success: true
            };
        }

        return result;
    }

    @action
    pushFormDialog(dialog: IFormDialog) {
        // console.log("pushFormDialog", dialog);

        dialog.key = `${dialog.form.name}-${Date.now()}`;
        this._state.formDialogs.push(dialog);
    }

    @action
    replaceFormDialog(dialog: IFormDialog) {
        dialog.key = `${dialog.form.name}-${Date.now()}`;
        this._state.formDialogs = [dialog];
    }

    async dataFormAsync(
        path: string,
        id?: string,
        onAction?: (dialog: IFormDialog, action?: Action, lastResult?: IDataFormActionResponse) => Promise<boolean>,
        initialValues?: { [name: string]: any }
    ): Promise<boolean> {

        var uri = new URI(`dataform:${path}`);
        if (id) {
            if (uri.path.indexOf("{{id}}") > 0) {
                uri.path = uri.path.replace("{{id}}", id);
            } else if (!uri.query) {
                uri.searchParams.append("id", encodeURIComponent(id));
            }
        }

        if (initialValues) {
            // pass them as query parameters to seed form
            Object
                .keys(initialValues)
                .forEach(x => {
                    if (uri.searchParams.has(x)) return;

                    uri.searchParams.set(x, initialValues[x]);
                });
        }

        var parsedUrl = uri.getPathAndQuery();

        const actionForm = await DataService()
            .dataFormAsync(parsedUrl)
            .catch((response) => {
                console.error(`Failed to get form: ${parsedUrl}`, response);
            });

        if (actionForm) {
            if (initialValues) {
                // set default values for editable fields that don't have one
                actionForm.fields?.forEach((field) => {
                    if (field.isReadOnly || !field.name || (field.defaultValue !== undefined && field.defaultValue !== null)) return;

                    if (field.name in initialValues) {
                        field.defaultValue = initialValues[field.name];
                    }
                });
            }

            this.pushFormDialog({
                form: Form.create(actionForm, parsedUrl),
                // url: path,
                onActionAsync: onAction
            });

            return true;
        }

        return false;
    }

    // @action
    closeDataFormDialogAsync = async (implicit?: boolean, action?: Action, lastResult?: IDataFormActionResponse) => {
        // console.log(`closeDataFormDialog: ${implicit}`, this._state.formDialogs);

        const formDialog = this.formDialog;
        if (!formDialog) return;

        if (formDialog.onActionAsync) {
            // give a chance for the caller to override handling the action
            if (await formDialog.onActionAsync(formDialog, action ?? { name: "#close" }, lastResult)) return;
        }

        this._state.formDialogs = implicit ? this._state.formDialogs.filter((x) => x !== formDialog) : [];
    };

    onDataFormDialogActionAsync = async (action: Action) => {
        // console.log("app::onDataFormDialogAction", action);

        const formDialog = this.formDialog;
        if (!formDialog) return null;

        if (formDialog.onActionAsync) {
            // give a chance for the caller to override handling the action
            if (await formDialog.onActionAsync(formDialog, action)) {
                return;
            }
        }

        if (action.name?.startsWith("#")) {
            const promise = formDialog.form.executeAsync(action);
            if (promise) await promise;

            switch (action.name) {
                case "#design": // design form,do not close form
                    break;

                case "#cancel":
                case "#ok":
                case "#reload": // dataview should reload
                default:
                    // by default, client side actions close window
                    await this.closeDataFormDialogAsync(true);
                    break;
            }

            return true;
        }

        if (URI.isUri(action?.name)) {
            // url
            await this.closeDataFormDialogAsync(true);
            return await this.selectPageAsync(action.name!);
        }

        if (formDialog.form.url) {
            // call controller
            const values = formDialog.form.values;

            const result = await this.dataFormActionAsync(formDialog.form.url, action, values);
            if (result.success) {
                await this.closeDataFormDialogAsync(
                    true,
                    {
                        name: "#close",
                        action: result.nextUrl ?? "#close"
                    },
                    result
                );
            } else if (result.message) {
                formDialog.form.error = result.message;
            }

            return result;
        }

        // local function
        const promise = formDialog.form.executeAsync(action);
        await this.closeDataFormDialogAsync(
            true,
            {
                name: "#close"
            },
            {
                action: "",
                success: !!promise
            }
        );

        return !!promise;
    };

    async dataFileActionAsync(path: string, actionName: string, parameters?: any): Promise<string | undefined> {
        const request: IDataFormActionRequest = {
            action: actionName,
            parameters,
            selectedIds: [] // this.selectedIds
        };

        return DataService().dataFileActionAsync(path, request, `${actionName}.csv`);
    }

    async executeAsync(action: IAction, context?: string, ...args: any[]): Promise<any> {
        // alert(`app::execute ${action}`);

        if (typeof action === "string" && URI.isUri(action)) {
            // uri
            return await this.selectPageAsync(action);
        }

        return Default.actions.execute(action, context || "app", ...args);
    }

    private upgradePageUrl(nameOrUrl: string): ParsedPageUrl | null {
        if (nameOrUrl.startsWith("page:")) {
            const urlObj = new URI(nameOrUrl);
            const args: { [name: string]: string } = {};
            urlObj.searchParams.forEach((v, k) => (args[k] = v));

            return {
                pageName: urlObj.path.substring(1), // remove leading /
                url: nameOrUrl,
                id: urlObj.searchParams.get("id"),
                args
            };
        }

        let index = nameOrUrl.indexOf("?");
        if (index > 0) {
            // Page?id=...&other=...
            // "proper url" without protocol or base path
            const page = nameOrUrl.substring(0, index);
            const url = `page:/${nameOrUrl}`;
            const urlObj = new URI(url);
            const id = urlObj.searchParams.get("id");
            return {
                pageName: page,
                url: `page:/${nameOrUrl}`,
                id,
                args: id ? { id } : undefined
            };
        }

        index = nameOrUrl.indexOf("=");
        if (index > 0) {
            // Page=id
            // old url, upgrade
            const page = nameOrUrl.substring(0, index);
            const parts = nameOrUrl.substring(index + 1).split("&");
            const id = parts[0];
            const args = {
                id
            };
            let url = `page:/${page}?id=${id}`;
            for (var c = 1; c < parts.length; c++) {
                const tokens = parts[c].split("=");
                if (tokens.length === 2) {
                    args[tokens[0]] = tokens[1];
                    url += `&${tokens[0]}=${tokens[1]}`;
                }
            }

            return {
                pageName: page,
                url,
                id,
                args: args
            };
        }

        index = nameOrUrl.indexOf("(");
        if (index > 0 && nameOrUrl.endsWith(")")) {
            // Page(id)
            // just in case
            const page = nameOrUrl.substring(0, index);
            const id = nameOrUrl.substring(index + 1, nameOrUrl.length - 1);
            return {
                pageName: page,
                url: `page:/${page}?id=${id}`,
                id,
                args: id ? { id } : undefined
            };
        }

        index = nameOrUrl.indexOf("/");
        if (index > 0) {
            alert(`Invalid url: ${nameOrUrl}`);
            return null;
        }

        return {
            pageName: nameOrUrl,
            url: `page:/${nameOrUrl}`,
            id: undefined,
            args: undefined
        };
    }

    private async createPageAsync(data: IPage): Promise<Page> {
        const page = createPage(data);

        if (data.appMenu) {
            await this.loadMenuAsync(data.appMenu);
        }

        return page;
    }

    private async redirectPage(parsedPageUrl: ParsedPageUrl, page: IPage): Promise<Page | undefined> {
        if (!page.name) throw "missing page";

        const path = page.name.replace("{{id}}", parsedPageUrl.id ?? "MISSING");
        const data = await DataService()
            .pageAsync(path.substring("page:/".length))
            .catch((response) => {
                console.error(`Failed to get page: ${path}`, response);
            });

        if (!data) {
            parsedPageUrl.page = undefined;
            return undefined;
        }

        parsedPageUrl.page = await this.createPageAsync(data as IPage);

        return parsedPageUrl.page;
    }

    private async loadPage(parsedPageUrl: ParsedPageUrl): Promise<Page | undefined> {
        // if the name (e.g. path - leading /) has multiple parts, it is an path to an API resource
        if (parsedPageUrl.pageName.includes("/") && parsedPageUrl.url.startsWith("page:/")) {
            const uri = new URI(parsedPageUrl.url);

            // not the standard path
            let page = await DataService()
                .pageAsync(uri.getPathAndQuery())
                .catch((response) => {
                    console.error(`Failed to get page: ${parsedPageUrl.url}`, response);
                });

            if (!page) {
                parsedPageUrl.page = undefined;
                return undefined;
            }

            if (page.t === "Page" && page.name?.startsWith("page:/")) {
                return await this.redirectPage(parsedPageUrl, page);
            }

            // page from url can be dynamic (change based on the url)
            // console.log('loaded page (url)', parsedPageUrl, page);
            // if (!page.label) page.label = page.name;
            // page.name = parsedPageUrl.url;
            parsedPageUrl.page = await this.createPageAsync(page);

            return parsedPageUrl.page;
        }

        // load page by name
        const client = new api.AppConfigApi(this.apiConfig);
        let data = await client.appConfigGetPageFromConfig(parsedPageUrl.pageName).catch((reason) => {
            console.error("Failed to get page", reason, parsedPageUrl);
            return undefined;
        });

        if (!data) {
            parsedPageUrl.page = undefined;
            return undefined;
        }

        const page = data as IPage;
        if (page.t === "Page" && page.name?.startsWith("page:/")) {
            return await this.redirectPage(parsedPageUrl, page);
        }

        // page from config is (probably) "stable" (same response for a name)
        // data.name = parsedPageUrl.url;
        // console.log('loaded page (from config)', data);
        parsedPageUrl.page = await this.createPageAsync(data as IPage);

        return parsedPageUrl.page;
    }
}

export default function get(): App {
    return Default.ui.get("app");
}
