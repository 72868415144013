import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Avatar, Badge, IconButton, Popover, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import * as api from "@crochik/pi-api";
import App from "src/pi/application/App";
import { useApplicationContext } from "src/pi/application/ApplicationContext";
import Dialog from "src/pi/services/Dialog";
import { DataViewComponent } from "../DataGridComponent";
import { OnClickArgs } from "../DataViewComponent/OnClickArgs";
import { addMessageListener, enableNotifications, isRegistered } from "./firebase";

export { isRegistered, unregister } from "./firebase";

interface NotificationData {
    id?: string;
    objectType?: string;
    objectId?: string;
    category?: string;
    backgroundUrl?: string;
    url?: string;
    action?: string;
}

interface IMessage {
    title: string;
    body: string;
    data: NotificationData;
}

export function NotificationMenu() {
    const [initials, setInitials] = useState<string>();
    const [unread, setUnread] = useState<boolean>(false);
    const [isRegisteredForNotifications, setRegistered] = useState<boolean>(false);
    const [isPopupVisible, showPopup] = useState<boolean>(false);
    const [lastMessage, setLastMessage] = useState<IMessage>();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    // const windowFocused = useWindowFocus();

    const appContext = useApplicationContext();
    const { user, embedded } = appContext || {};

    useEffect(() => {
        // TODO: get count of unread messages
        // ...

        const unsubscribe = addMessageListener((payload) => {
            setUnread(true);

            if (payload.notification?.body) {
                console.log("Message received. ", payload);

                setLastMessage({
                    title: payload.notification.title ?? "Notification",
                    body: payload.notification.body,
                    data: payload.data as NotificationData,
                });
            }
        });

        return () => {
            console.log("unsubscribe");
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        console.log(`user changed: ${user?.id}`);

        if (!user?.name || !user?.id) {
            setInitials(undefined);
            return;
        }
        const words = user.name.split(" ");
        setInitials(words[0][0].toUpperCase() + (words.length > 1 ? words[words.length - 1][0] : ""));

        setRegistered(isRegistered(user.id));
    }, [user?.id, user?.name]);

    const onEnableNotificationsClick = async () => {
        if (await enableNotifications()) {
            console.log("Successfully registered for push notifications");
            setRegistered(true);

            Dialog.inform({
                title: "Notifications",
                message: "Successfully registered for notifications",
            });
        }
    };

    const app = App();

    const openNotification = useCallback(
        (data?: NotificationData, modifierKeys?: boolean) => {
            if (!data?.id) return false;

            new api.NotificationApi(app.apiConfig).notificationMarkAsRead(data.id, false).catch((reason) => {
                alert("failed to get notification");
            });

            if (data.url && modifierKeys) {
                window.open(data.url, "_blank");
                return true;
            }

            if (data.action) {
                console.log("Execute action", data.action);
                app.executeAsync(data.action);
                return true;
            }

            return false;
        },
        [app]
    );

    const onClickNotification = (args: OnClickArgs) => {
        const { _id, Url, Action } = args.row as any;

        showPopup(false);

        return openNotification(
            {
                id: _id,
                url: Url,
                action: Action,
            },
            args.event?.ctrlKey || args.event?.metaKey
        );

        // new api.NotificationApi(app.apiConfig).markAsRead(_id)
        //     .catch((reason) => {
        //         alert("failed to get notification");
        //     });

        // if (Url && (args.event?.ctrlKey || args.event?.metaKey)) {
        //     window.open(Url, "_blank");
        //     return true;
        // }

        // if (Action) {
        //     console.log("Execute action", Action);
        //     app.execute(Action);
        //     return true;
        // }

        // // const action = Action ?? `dataForm://api/v1/CustomObject/Notification(${_id})/View`;

        // // fallback to dataview handler
        // return false;
    };

    const onOpenMessage = useCallback(
        (event: React.MouseEvent) => {
            setLastMessage(undefined);
            openNotification(lastMessage?.data, event?.ctrlKey || event?.metaKey);
        },
        [lastMessage?.data, openNotification]
    );

    const onOpenMessageInNewWindow = useCallback(
        (event: React.MouseEvent) => {
            setLastMessage(undefined);
            openNotification(lastMessage?.data, true);
        },
        [lastMessage?.data, openNotification]
    );

    const onCloseMessage = () => {
        setLastMessage(undefined);
    };

    if (embedded || !user?.id) return null;

    const togglePopup = (event: React.MouseEvent<HTMLDivElement>) => {
        console.log("toggle popuo");
        setAnchorEl(event.currentTarget);
        showPopup(!isPopupVisible);
        setUnread(false);
    };

    const onClosePopup = () => {
        showPopup(false);
    };

    const popup = (
        <Popover
            open={isPopupVisible}
            onClose={onClosePopup}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            anchorEl={anchorEl}
        >
            <div style={{ width: 400, height: 400, padding: 6, backgroundColor: "#eeeeee" }}>
                {/* <DataViewComponent view="/api/v1/CustomObject(Notification)" /> */}
                <DataViewComponent view="/api/v1/AppDataView/Notification: Recent" compact onClick={onClickNotification} />
            </div>
        </Popover>
    );

    if (!isRegisteredForNotifications) {
        return (
            <div onClick={onEnableNotificationsClick}>
                <Badge color="secondary" variant="dot">
                    <Avatar>{initials}</Avatar>
                </Badge>
            </div>
        );
    }

    const openAction = (
        <IconButton key="close" aria-label="Close" color="primary" onClick={onOpenMessageInNewWindow} size="large">
            <OpenInNewIcon />
        </IconButton>
    );

    return (
        <>
            <div onClick={togglePopup}>
                {unread ? (
                    <Badge variant="dot" color="secondary">
                        <Avatar>{initials}</Avatar>
                    </Badge>
                ) : (
                    <Avatar>{initials}</Avatar>
                )}
            </div>
            {popup}
            {lastMessage && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={openAction ? 7000 : 3000}
                    open={!!lastMessage}
                    onClose={onCloseMessage}
                    ContentProps={{
                        "aria-describedby": "message-id",
                    }}
                    message={
                        <>
                            <b>{lastMessage.title}</b>
                            <br />
                            <span id="message-id">{lastMessage.body}</span>
                        </>
                    }
                    action={openAction}
                    onClick={onOpenMessage}
                ></Snackbar>
            )}
        </>
    );
}
