import { observer } from "mobx-react";
import * as React from "react";

import { useState } from "react";
import { ScreenBreakpoint } from "@crochik/pi-api";
import App from "src/pi/application/App";
import { Loading } from "../Loading";
import CalendarViewComponent from "./CalendarViewComponent";
import { CardViewComponent } from "./CardViewComponent";
import { DataGridComponent1 } from "./DataGridComponent1";
import { DataGridProps } from "./DataGridProps";
import { DataViewBarChart } from "./DataViewBarChart";
import { DataViewContainer } from "./DataViewContainer";
import { DataViewContext, IDataViewContext } from "./DatavViewContext";
import { IDataViewComponentProps } from "./IDataViewComponentProps";
import MapViewComponent from "./MapViewComponent";

interface IAutoReloadViewComponentProps extends IDataViewContext {
    breakpoint: ScreenBreakpoint;
    compact?: boolean;
    component: string;
}

function AutoReloadViewComponent(props: IAutoReloadViewComponentProps) {
    const { breakpoint, compact, component, ...otherProps } = props;
    const [initialLoad, setInitialLoad] = useState(true);
    const [isLoading, setLoading] = useState(false);

    React.useEffect(() => {
        if (!breakpoint) return;

        if (initialLoad) {
            console.log('first render');
            setInitialLoad(false);
            return;
        }

        console.log('component changed', breakpoint);
        const { dataView } = props;

        if (isLoading) return;

        setLoading(true);
        dataView?.reloadAsync(breakpoint)
            .then(() => {
                setLoading(false);
            })
            .catch(reason => {
                setLoading(false);
            });

    }, [breakpoint])

    if (!props) return null;
    if (isLoading) return <Loading />;

    const { dataView, onClick, onObjectMenu } = otherProps;

    const calcProps: IDataViewComponentProps = {
        // dataView,
        onClick,
        onObjectMenu,
        view: dataView,
        compact,
    }

    return <InnerDataViewComponent {...calcProps} component={component} />
}

interface IInnerDataViewComponentProps extends IDataViewComponentProps {
    component?: string;
}

export function InnerDataViewComponent(props: IInnerDataViewComponentProps) {
    switch (props.component ?? props.view?.component) {
        case "Card":
            return <CardViewComponent {...props} />;

        case "Chart":
            return <DataViewBarChart dataView={props.view} />;

        case "Calendar":
            return <CalendarViewComponent {...props} />;

        case "Map":
            return <MapViewComponent {...props} />;
    }

    return <DataGridComponent1 {...props} />;
}

@observer
class AutoSize extends React.PureComponent<IDataViewContext> {
    render() {
        const breakpoint = App().breakpoint;
        return <AutoReloadViewComponent breakpoint={breakpoint} component={this.props.dataView.component} {...this.props} />
    }
}

export const DataViewComponent = (props: DataGridProps) => (
    <DataViewContainer {...props}>
        <DataViewContext.Consumer>
            {(context) => context ? <AutoSize {...context} /> : null}
        </DataViewContext.Consumer>
    </DataViewContainer>
);
