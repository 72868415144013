import FilterIcon from "@mui/icons-material/FilterList";
import ReorderIcon from "@mui/icons-material/Reorder";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, Popover, Toolbar, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { ScreenBreakpoint } from "@crochik/pi-api";
import { IActionMenuItem } from "src/pi/context/IMenu";
import { DataView } from "../../DataView";
import ActionMenuBar from "../ActionMenuBar";
import { SearchInput } from "../DataGridComponent/SearchInput";
import { FieldsComponent } from "../DataViewComponent/FieldsComponent";

interface IDataViewToolbarComponentProps {
    view: DataView;
    headerComponent?: React.ReactNode;
    args?: any[];
    onAction?: (action: IActionMenuItem, event: React.MouseEvent) => any;
    compact?: boolean;
}

export default function DataViewToolbarComponent(props: IDataViewToolbarComponentProps) {
    const { view, headerComponent, args, onAction } = props;
    const { selectedCount, isFilterVisible, search, breakpoint, component, isViewFilterable, filterVisible, menu } = view;
    const [reorderParent, setReorderParent] = useState<Element>();
    const [searchParent, setSearchParent] = useState<Element>();

    const filter = useCallback((criteria?: string) => {
        if (!view) return;
        view.searchOrFilter(criteria, args);
        if (criteria) {
            setSearchParent(undefined);
        }
    }, [view, args]);

    const onToggleFilter = useCallback(() => {
        if (!view) return;
        view.filterVisible = !filterVisible;
    }, [view, filterVisible]);

    const toggleReorder = useCallback((event: React.MouseEvent) => {
        if (!view) return;

        if (!!reorderParent) {
            setReorderParent(undefined);
            view.onColumnsChangedAsync();
        } else {
            setReorderParent(event?.target as Element);
        }
    }, [view, reorderParent]);

    const toggleSearch = useCallback((event: React.MouseEvent) => {
        if (!view) return;

        if (!!searchParent) {
            setSearchParent(undefined);
        } else {
            setSearchParent(event?.target as Element);
        }
    }, [view, searchParent]);

    const otherComponent = useCallback(() => {
        if (selectedCount) return null;
        return headerComponent ?? null;
    }, [headerComponent, selectedCount]);

    const searchComponent = useCallback(() => {
        if (!isFilterVisible || selectedCount) return null;

        if (breakpoint === ScreenBreakpoint.Xs || component === "Card") {
            return (
                <IconButton onClick={toggleSearch} color={searchParent || view.search ? "primary" : "default"}>
                    <SearchIcon />
                </IconButton>
            )
        }

        return (
            <SearchInput
                variant="standard"
                label={view.isSearchable ? "Search" : "Filter"}
                value={search || ""}
                onSearch={filter}
                style={{ width: '150px', marginLeft: headerComponent ? 16 : undefined }}
                collapsible={true}
                disabled={view.isLoading}
            />
        );

    }, [isFilterVisible, selectedCount, headerComponent, search, breakpoint, component, searchParent]);

    const filterComponent = useCallback(() => {
        if (selectedCount || !isViewFilterable) return null;
        return (
            <IconButton key="filter" color={filterVisible ? "primary" : "inherit"} onClick={onToggleFilter} title="Filter" >
                <FilterIcon />
            </IconButton>
        );

    }, [isViewFilterable, selectedCount, filterVisible]);

    const reorderComponent = useCallback(() => {
        if (selectedCount) return null;
        if (component === "Calendar") return null;
        return (
            <IconButton onClick={toggleReorder} color={reorderParent ? "primary" : "default"}>
                <ReorderIcon />
            </IconButton>
        );

    }, [selectedCount, component, reorderParent]);

    const menuComponent = useCallback(() => {
        if (selectedCount || !menu) return null;
        return (
            <ActionMenuBar menu={view.menu} context={view.context} onAction={onAction} />
        );

    }, [selectedCount, menu]);

    return (
        <>
            <Toolbar
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    zIndex: 1,
                    position: "sticky",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    justifyContent: {
                        xs: "center",
                        sm: "space-between",
                    },
                }}
            >
                <Box
                    sx={{
                        display: { xs: "none", sm: "block" },
                    }}
                >
                    <Typography variant="h6" color="primary" id="tableTitle">
                        {view.title}
                    </Typography>
                </Box>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {otherComponent()}
                    {searchComponent()}
                    {filterComponent()}
                    {reorderComponent()}
                    {menuComponent()}
                </div>
            </Toolbar>
            <Popover
                open={!!reorderParent}
                anchorEl={reorderParent}
                onClose={toggleReorder}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <div
                    style={{
                        maxHeight: "600px",
                        maxWidth: "600px",
                        overflow: "auto",
                        padding: "12px",
                        backgroundColor: "#efefef",
                    }}
                >
                    <FieldsComponent dataView={view} />
                </div>
            </Popover>
            <Popover
                open={!!searchParent}
                anchorEl={searchParent}
                onClose={toggleSearch}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <div
                    style={{
                        maxHeight: "600px",
                        maxWidth: "600px",
                        overflow: "auto",
                        padding: "12px",
                        backgroundColor: "#efefef",
                    }}
                >
                    <SearchInput
                        variant="standard"
                        label={view.isSearchable ? "Search" : "Filter"}
                        // placeholder={view.isSearchable ? "Search..." : "Filter..."}
                        value={search || ""}
                        onSearch={filter}
                        collapsible={false}
                        disabled={view.isLoading}
                    />
                </div>
            </Popover>
        </>
    )
}