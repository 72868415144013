import { Paper } from "@mui/material";
import { GoogleApiWrapper, IMapProps, IProvidedProps, Map } from "google-maps-react";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDataViewContext } from "../DatavViewContext";
import { IDataViewComponentProps } from "../IDataViewComponentProps";
import { LatLngBounds } from "../../../DataView";
import { reaction, toJS } from "mobx";
import * as api from "@crochik/pi-api";
import { TYPE } from "../../../../context/IForm";

interface IMapContainerProps extends IProvidedProps {
    // children?: React.ReactNode;
}

interface LatLngLiteral {
    lat: number;
    lng: number;
}

function haversine_distance(mk1: LatLngLiteral, mk2: LatLngLiteral, metric?: boolean) {
    const R = metric ? 6371.0710 : 3958.8; // Radius of the Earth in miles
    const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

    const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    return d;
}

function MapContainerHook(props: IMapContainerProps) {
    const viewContext = useDataViewContext();
    const { dataView, onClick } = viewContext;
    const mapRef = useRef<google.maps.Map>();
    const [isDragging, setDragging] = useState(false);
    const [timeoutId, setTimeoutId] = useState<number>();
    const [bounds, setBounds] = useState<LatLngBounds>();
    const markers = useRef<google.maps.Marker[]>([]);
    const locationField = useRef<api.FormField>();
    const locationDistanceField = useRef<api.FormField>();
    const skipRecentering = useRef(false);

    const infoWindow = new google.maps.InfoWindow();

    useEffect(() => {
        return reaction(() => dataView.isLoading, () => {
            if (!mapRef.current || dataView.isLoading) return;
            onDataLoaded(mapRef.current);
        });
    }, [dataView]);

    const renderField = (field: api.FormField, value: object) => {
        if (!value || !field?.name) return null;

        if (!dataView.isFieldVisible(field.name)) return null;

        switch (field.type) {
            case TYPE.TEXT:
            case TYPE.EMAIL:
            case TYPE.ADDRESS:
            case TYPE.PHONE:
                return `<span style="font-size: 10pt">${value}</span>`;

        }

        return null;
    };

    const showPopup = (marker: google.maps.Marker, row: object) => {
        if (!!locationField.current?.options) {
            const options = locationField.current.options as api.LocationFieldOptions;
            // options.fields
            // options.formLayout
            // ...
        }

        const content = dataView.fields
            .map(field => renderField(field, row[field!.name!]))
            .filter(f => !!f);

        const info = new google.maps.InfoWindow({
            content: content.join("<br/>")
        });

        info.open({
            anchor: marker,
            map: marker.getMap()
        });
    };

    const styleFeature = (feature: google.maps.Data.Feature): google.maps.Data.StyleOptions => { // 
        return {
            fillColor: "#ff0000",
            fillOpacity: 0.5,
            strokeColor: "#ff0000",
            strokeWeight: 2
        };
    };

    const onDataLoaded = (map?: google.maps.Map) => {
        // const options = dataView.options as api.MapViewOptions;
        const { fields, filterForm } = dataView;
        const lField = fields.find(x => x.type === "location");
        const ldField = filterForm?.fields?.find(x => x.type === "locationdistance");
        let iconOptions: api.ImageFieldOptions;
        let iconFieldName: string;
        if (!lField?.name || !ldField?.name || !ldField?.options) {
            // TODO: show error
            console.error("missing location field");
            return;
        }

        const options = lField!.options as api.LocationFieldOptions;
        iconFieldName = options.iconFieldName!;
        console.log("icon field", iconFieldName);

        const iconField = fields.find(x => x.name === iconFieldName);
        if (!!iconField) {
            if (iconField?.type !== "image" || !iconField.options) {
                console.error("invalid icon field/options");
                return;
            }

            iconOptions = iconField.options as api.ImageFieldOptions;
        }

        if (!ldField?.name || !ldField?.options) {
            // TODO: show error
            console.error("missing location distance field/options");
            return;
        }

        locationField.current = lField;
        locationDistanceField.current = ldField;

        const isLocationFixed = !!dataView.fixedFields && !!lField?.name && lField.name in dataView.fixedFields;
        console.log("location is fixed", isLocationFixed, dataView.fixedFields, lField?.name);
        const locationName = lField!.name;

        const currLocation = dataView.filterForm?.getValue(lField?.name);
        let center: { lat: number, lng: number } | undefined;

        if (currLocation) {
            if (typeof (currLocation) !== "string") {
                console.error("invalid location value");
            }

            const parts = currLocation.split(",");
            if (parts.length === 2) {
                center = { lat: parseFloat(parts[1]), lng: parseFloat(parts[0]) };
            }
        }

        let newBounds = new google.maps.LatLngBounds();
        let count = 0;

        if (markers.current) {
            markers.current.forEach(x => {
                x.setMap(null);
            });
        }

        if (!locationName) {
            console.error("Missing location field name");
            return;
        }

        const getIcon = (value) => {
            const map = iconOptions?.imageUris && value in iconOptions.imageUris ? iconOptions.imageUris[value] : value;
            console.log(map, value);
            return map;
        };

        const newMarkers = dataView.records
            .map((x, i) => {
                const row = dataView.get(x);
                const location = row[locationName];
                if (!location || !("coordinates" in location) || !Array.isArray(location["coordinates"]) || location.type !== "Point") {
                    return null;
                }

                const coordinates = location["coordinates"] as string[];

                const lng = Number.parseFloat(coordinates[0]);
                const lat = Number.parseFloat(coordinates[1]);
                newBounds = newBounds.extend({ lat, lng });
                count++;

                const icon = row[iconFieldName];

                const opts = {
                    position: new google.maps.LatLng(lat, lng),
                    map,
                    clickable: true,
                    // label: row["Name"],
                    title: row["Name"],
                    icon: getIcon(icon)
                };

                const marker = new google.maps.Marker(opts);

                marker.addListener("click", e => {
                    console.log(marker, e);

                    const row = dataView.get(x);

                    showPopup(marker, row);
                    // onClick?.(e["domEvent"], {
                    //     row,
                    // });
                });

                marker.addListener("dblclick", e => {
                    console.log(marker, e);

                    const row = dataView.get(x);

                    // showPopup(marker, row);
                    onClick?.(e["domEvent"], {
                        row
                    });
                });

                return marker;
            })
            .filter(x => !!x);

        if (isLocationFixed) {
            const opts = {
                position: center,
                map,
                clickable: true,
                title: "Location",
                icon: "./icons/fcimarker3.png"
            };

            newMarkers.push(new google.maps.Marker(opts));
        }

        markers.current = newMarkers as google.maps.Marker[];

        if (skipRecentering.current) {
            skipRecentering.current = false;
            return;
        }

        if (count > 0) {
            setBounds(undefined);

            if (!map?.getBounds()?.intersects(newBounds)) {
                dataView.mapBounds = newBounds.toJSON();
                map!.fitBounds(dataView.mapBounds);
            }
        }

        if (center) {
            setBounds(undefined);
            map!.setCenter(center);
            dataView.mapBounds = map!.getBounds()?.toJSON();
            return;
        }
    };

    const onReady = (mapProps?: IMapProps, map?: google.maps.Map, event?) => {
        console.log("ready");

        mapRef.current = map;
        skipRecentering.current = false;

        onDataLoaded(map);

        map!.data.setStyle(styleFeature);

        const locationButton = document.createElement("div");
        locationButton.innerHTML = "<img src='/icons/mylocation.png' style='margin: 8px; cursor: pointer'/>";

        map!.controls[google.maps.ControlPosition.RIGHT_CENTER].push(locationButton);

        const handleLocationError = (
            browserHasGeolocation: boolean,
            infoWindow: google.maps.InfoWindow,
            pos: google.maps.LatLng
        ) => {
            infoWindow.setPosition(pos);
            infoWindow.setContent(
                browserHasGeolocation
                    ? "Error: The Geolocation service failed."
                    : "Error: Your browser doesn't support geolocation."
            );
            infoWindow.open(map);
        };

        locationButton.addEventListener("click", () => {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                // locationButton.disabled = true;
                locationButton.style.opacity = ".5";
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        //
                        // infoWindow.setPosition(pos);
                        // infoWindow.setContent("Your Location");
                        // infoWindow.open(map);

                        map!.setCenter(pos);
                        // locationButton.disabled = false;
                        locationButton.style.opacity = "1";
                    },
                    () => {
                        // locationButton.disabled = false;
                        locationButton.style.opacity = ".5";
                        handleLocationError(true, infoWindow, map!.getCenter()!);
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infoWindow, map!.getCenter()!);
            }
        });
    };

    const loadData = useCallback((newBounds?: LatLngBounds) => {
        if (!newBounds) return;

        skipRecentering.current = true;
        dataView.mapBounds = newBounds;

        if (!bounds) {
            // console.log('update bounds', bounds);
            setBounds(newBounds);
            return;
        }

        const current = new google.maps.LatLngBounds(newBounds);
        if (current.equals(bounds)) {
            // console.log("bounds have not changed");
            return;
        }

        // if (current.union(bounds).equals(bounds)) {
        //     // console.log("current bounds contains new bounds");
        //     return;
        // }

        setBounds(newBounds);

        // console.log("debounce");

        if (timeoutId) {
            console.log("clear previous timer");
            clearTimeout(timeoutId);
        }

        const id = window.setTimeout(() => {
            if (!locationField.current) return;

            const center = mapRef.current!.getCenter()?.toJSON();
            // const distanceFilter = { // : api.LocationDistanceFilterValue
            //     latitude: center?.lat.toString(),
            //     longitude: center?.lng.toString(),
            //     // min: 0,
            //     max: haversine_distance(current.getSouthWest().toJSON(), current.getNorthEast().toJSON(), true) / 2
            // };

            const longitude = center?.lng.toString();
            const latitude = center?.lat.toString();
            const max = haversine_distance(current.getSouthWest().toJSON(), current.getNorthEast().toJSON(), true) / 2;

            const isLocationFixed = !!dataView.fixedFields && !!locationField.current?.name && locationField.current.name in dataView.fixedFields;

            const conditions: api.Condition[] = [];
            if (!isLocationFixed && locationField.current!.name) {
                conditions.push({
                    fieldName: locationField.current!.name,
                    operator: api.Operator.Eq,
                    value: `${longitude},${latitude}`
                });
            }

            if (locationDistanceField.current!.name) {
                conditions.push({
                    fieldName: locationDistanceField.current!.name,
                    operator: api.Operator.Lt,
                    value: max
                });
            }

            if (conditions.length > 0) {
                dataView.setFilters(conditions, locationDistanceField.current!.name!, false);
            }

        }, 1000);

        setTimeoutId(id);

    }, [dataView, bounds, locationDistanceField.current, locationField.current]);

    const onBoundsChanged = (mapProps?: IMapProps, map?: google.maps.Map, event?) => {
        if (!bounds) {
            setBounds(map?.getBounds()?.toJSON());
            return;
        }

        if (isDragging) return;

        loadData(map?.getBounds()?.toJSON());
    };

    const onDragstart = (mapProps?: IMapProps, map?: google.maps.Map, event?) => {
        // console.log("onDragstart");
        setDragging(true);
    };

    const onDragend = (mapProps?: IMapProps, map?: google.maps.Map, event?) => {
        // console.log("onDragend");
        setDragging(false);

        loadData(map?.getBounds()?.toJSON());
    };

    return (
        <Map
            google={props.google}
            onReady={onReady}
            onBoundsChanged={onBoundsChanged}
            onDragstart={onDragstart}
            onDragend={onDragend}
            zoom={12}
        />
    );
}

const Wrapper = GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
})(MapContainerHook);

function MapViewComponentHook(props: IDataViewComponentProps) {
    const { compact } = props;

    return (
        <Paper
            sx={{
                overflow: "auto",
                height: compact ? "100%" : "calc(100% - 64px)",
                marginTop: "1px"
            }}
        >
            <div style={{ position: "relative", width: "100%", height: "100%" }}>
                <Wrapper />
            </div>
        </Paper>
    );
}

@observer
export default class MapViewComponent extends React.Component<IDataViewComponentProps> {
    render() {
        // const { view } = this.props;
        // const {isLoading} = view;
        return (
            // <>
            //     {isLoading && <Loading/>}
            <MapViewComponentHook  {...this.props} />
            // </>
        );
    }
}