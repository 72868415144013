import { Divider, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as api from "@crochik/pi-api";
import { evaluateWithContext } from "src/pi/context/Expression";
import { NamedIcon } from "../ActionMenuBar/NamedIcon";
import { Loading } from "../Loading";
import { parseError } from "../../../api/parseError";
import App from "../../../application/App";


interface IMenuItemProps {
    context: object;
    onAction?: (action: api.ActionMenuItem, event: React.MouseEvent) => void;
}

interface IPopupMenuProps extends IMenuItemProps {
    menu: api.Menu;
}

function PopupMenu({ menu, context, onAction }: IPopupMenuProps) {
    const [anchor, setAnchor] = useState<HTMLElement>();

    const onOpenMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchor(e.currentTarget);
    }

    const onCloseMenu = () => {
        setAnchor(undefined);
    }

    const onItemAction = (action: api.ActionMenuItem, event: React.MouseEvent) => {
        onCloseMenu();
        onAction?.(action, event);
    }

    return (
        <>
            <MenuItemComponent item={menu} onClick={onOpenMenu} enabled={true} />

            {anchor && (
                <Menu
                    id={`${menu.name}_menu`}
                    key={`${menu.name}_menu`}
                    keepMounted={false}
                    open={true}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    onClose={onCloseMenu}
                    PaperProps={{
                        style: {
                            // maxHeight: 400,
                            minWidth: 200,
                        },
                    }}
                >
                    {menu.items && menu.items.map((x) => <ProxyMenuItem key={x.name} item={x} context={context} onAction={onItemAction} />)}
                </Menu>
            )}
        </>
    );
}

interface IMenuItemComponentProps {
    item: api.MenuItem;
    enabled: boolean;
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

function MenuItemComponent({ item, enabled, onClick }: IMenuItemComponentProps) {
    if (item.icon) {
        return (
            <MenuItem key={item.name} disabled={!enabled} onClick={onClick}>
                <div style={{ display: 'flex', justifyItems: 'center', flexDirection: 'row' }}>
                    <div style={{ paddingRight: 12 }}><NamedIcon icon={item.icon} /></div>
                    {item.label ?? item.name}
                </div>
            </MenuItem>
        )
    }

    return (
        <MenuItem key={item.name} disabled={!enabled} onClick={onClick}>
            {item.label ?? item.name}
        </MenuItem>
    )
}

interface IProxyMenuItemProps extends IMenuItemProps {
    item: api.ActionMenuItem;
}

export function ProxyMenuItem({ item, context, onAction }: IProxyMenuItemProps) {

    const visible = evaluateWithContext(context, item.visible);
    if (!visible) return null;

    if (item.type === "Menu") {
        return <PopupMenu key={item.name} context={context} onAction={onAction} menu={item} />
    }

    if (!item.action) return null;

    const enabled = evaluateWithContext(context, item.enable);

    const onClick = (event: React.MouseEvent) => {
        if (!enabled || !item.action) {
            return;
        }
        onAction?.(item, event);
    }

    return <MenuItemComponent key={item.name} item={item} onClick={onClick} enabled={enabled} />
}

interface IObjectPopupMenuProps {
    anchor?: HTMLElement;
    onClose?: () => void;
    onAction?: (action: api.ActionMenuItem) => void;
    selectedObject?: object;
    actions?: api.MenuItem[];

    objectType?: string | null;
    objectId?: string;
}

export function ObjectPopupMenu({ anchor, onClose, selectedObject, actions, onAction, objectType, objectId }: IObjectPopupMenuProps) {
    const [isLoading, setLoading] = useState<boolean>();
    const [userActionsMenu, setUserActionsMenu] = useState<api.Menu | undefined>();

    useEffect(() => {
        const loadUserActions = objectId && objectType;
        if (!loadUserActions) return;

        setLoading(true);
        setUserActionsMenu(undefined);

        console.log('load actions for', objectType, objectId);

        new api.UserActionApi(App().apiConfig)
            .userActionGetActionsMenuForObject(objectType, objectId)
            .then((result) => {
                if (result && result.items) {
                    setUserActionsMenu(result);
                    // setMenu({
                    //     name: "Actions",
                    //     icon: api.Icons.Action,
                    //     items: [result]
                    // });
                }
                setLoading(false);
            })
            .catch(reason => {
                console.error(parseError(reason));
                setLoading(false);
            });

    }, [selectedObject, objectType])

    if (!anchor) return null;

    const context = {
        selectedCount: 1,
        ...selectedObject
    };

    return (
        <Menu
            id="long-menu"
            keepMounted={false}
            open={true}
            anchorEl={anchor}
            onClose={onClose}
            PaperProps={{
                style: {
                    // maxHeight: 400,
                    minWidth: 200,
                },
            }}
        >
            {actions?.map(x => <ProxyMenuItem key={x.name} item={x} context={context} onAction={onAction} />)}
            {userActionsMenu?.items && userActionsMenu.items?.length > 0 && (
                <>
                    {actions && actions.length > 0 && <Divider />}
                    {userActionsMenu.items.map(x => <ProxyMenuItem key={x.name} item={x} context={context} onAction={onAction} />)}
                </>
            )}
            {isLoading && <Loading />}
        </Menu>
    );
}
