import { IObjectFieldOptions } from "src/pi/context/IForm";
import { Form } from "../../../Form";
import { FieldProps } from "../FieldProps";
import { StandardObjectField } from "./StandardObjectField";
import { FormObjectField } from "./FormObjectField/FormObjectField";
import { UnknownValue } from "../../../ObjectTypeDesigner/UnknownValue";

export interface IObjectFieldProps extends FieldProps<object> {
    form: Form;
    readonly?: boolean;
}

export function ObjectField(props: IObjectFieldProps) {
    const { field, value } = props;
    const { options: fieldOptions } = field;
    const options = fieldOptions as IObjectFieldOptions;

    // TODO: use another property in options to make explicit ("component"?)
    // could also check editForms, add form but...
    // ...
    switch (options.objectType) {
        case "*":
            // for now just shows the value (can't edit/add)
            return <UnknownValue value={value} />;

        case "Form":
            return <FormObjectField {...props} />;
    }

    return <StandardObjectField {...props} />;
}