import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Alert, Box, IconButton, PaletteColor, Stack, Typography, useTheme } from "@mui/material";
import { ColorPartial } from '@mui/material/styles/createPalette';
import { StyleSheet, css } from "aphrodite";
import { toJS } from 'mobx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loading } from '../../Loading';
import { IOnClickArgs, useDataViewContext } from '../DatavViewContext';
import { IDataViewComponentProps } from '../IDataViewComponentProps';
import { ICalendarRow } from "./ICalendarRow";
import { colors } from "./colors";

interface IData {
    appointments: { [day: string]: ICalendarRow[] };
    users: { [userId: string]: PaletteColor };
}

interface ICalendarViewProps {
    data: IData;
    onClick: (e: React.MouseEvent, args: IOnClickArgs) => void;
}

interface IAppointmentCardProps extends ICalendarViewProps {
    appointment: ICalendarRow;
}

function AppointmentCard({ appointment, data, onClick }: IAppointmentCardProps) {
    const isAppt = appointment.Type === "Appointment";
    const color = data.users[appointment.UserId];
    const [isProcessing, setProcessing] = useState(false);
    const { onObjectMenu, dataView } = useDataViewContext();

    const onShowPopup = (e: React.MouseEvent) => {
        e.stopPropagation();
        onObjectMenu?.(e.target as HTMLElement, appointment, "Appointment");
    }

    const onClickCard = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isAppt) return;

        if (isProcessing) return;
        setProcessing(true);

        const id = dataView.id(appointment) as string;
        dataView
            .handleUrlAsync(`dataForm:/api/v1/CustomObject/Appointment(${id})/View`, "View")
            .then(x => {
                setProcessing(false);
            })
            .catch(error => {
                setProcessing(false);
            });
    };

    return (
        <div
            className={css(styles.card, isAppt ? styles.cardAppointment : styles.cardEvent)}
            style={{ color: isAppt ? 'white' : color.dark, backgroundColor: isAppt ? color.dark : undefined, borderColor: isAppt ? undefined : color.dark }}
            onClick={onClickCard}
        >
            <div className={css(styles.cardHeader)}>
                <Typography>{appointment.Name}</Typography>
                {isAppt && <IconButton onClick={onShowPopup}><MoreVertIcon htmlColor='white' /></IconButton>}
            </div>
            <Typography fontSize={14}>
                {moment(appointment.Start).format("LT")} - {moment(appointment.End).format("LT")}&nbsp;-&nbsp;{appointment['UserId|Name']}
            </Typography>
            {isProcessing && <Loading />}
        </div>
    )
}

interface IDayProps extends ICalendarViewProps {
    start: moment.Moment;
}

function Day(props: IDayProps) {
    const { start, data } = props;

    const key = start.format("MM/DD/YY");
    const appts = data.appointments[key];
    if (!appts) return null;

    return (
        <div className={css(styles.day)}>
            <div className={css(styles.dayDate)}>
                <Typography>
                    {start.format("ddd")}
                </Typography>
                <Typography fontSize={20}>
                    {start.format("D")}
                </Typography>
            </div>
            <Stack spacing={1} className={css(styles.appointments)}>
                {
                    appts && appts.map(appointment => <AppointmentCard {...props} appointment={appointment} key={appointment._id} />)
                }
            </Stack>
        </div>
    )
}

interface IWeekProps extends ICalendarViewProps {
    start: moment.Moment;
}

function Week(props: IWeekProps) {
    const { start, data } = props;
    const days = [0, 1, 2, 3, 4, 5, 6];
    const end = start.clone().add(6, 'days');
    const label = start.month() === end.month() ? `${start.format("MMM D")} - ${end.format("D")}` : `${start.format("MMM D")} - ${end.format("MMM D")}`;

    return (
        <div className={css(styles.week)}>
            <div className={css(styles.weekLabel)}>
                <Typography>{label}</Typography>
            </div>
            <Stack spacing={1} className={css(styles.days)}>
                {days.map((x) => {
                    const day = start.clone().add(x, 'days');
                    return (
                        <Day {...props} start={day} key={x} />
                    );
                })}
            </Stack>
        </div>
    )
}

interface IAgendaViewProps extends IDataViewComponentProps {
    loadTimestamp?: Date;
}

export function AgendaView({ view, compact, loadTimestamp, onClick }: IAgendaViewProps) {
    const { records } = view;
    const { filterForm } = view;
    const [error, setError] = useState<string>();
    const [weeks, setWeeks] = useState<moment.Moment[]>();
    const [data, setData] = useState<IData>();
    const theme = useTheme();

    useEffect(() => {
        const filter = toJS(filterForm?.getValues());
        if (!Array.isArray(filter?.['Start'])) {
            // TODO: calculate boundaries from data?
            // ... 
            console.error("Unexpected Date Range", filter?.["Start"]);
            return;
        }

        const range = filter!['Start'] as string[];
        if (range.length !== 2) {
            // TODO: calculate boundaries from data?
            // ... 
            console.error("Unexpected Date Range", range);
            return;
        }

        const weeks: moment.Moment[] = [];
        for (let start = moment(range[0]).startOf('week'); start.isBefore(moment(range[1])); start = start.add(1, 'week')) {
            weeks.push(start.clone())
        }

        const data: IData = {
            appointments: {},
            users: {},
        };

        const users: { [userId: string]: any } = {};
        records.forEach((row) => {
            const appointment = view.get(row) as ICalendarRow;
            const day = moment(appointment.Start).format("MM/DD/YY");
            if (day in data.appointments) {
                data.appointments[day].push(appointment);
            } else {
                data.appointments[day] = [appointment];
            }
            users[appointment.UserId] = appointment['UserId|Name'];
        })

        Object.keys(users).map((x, i) => data.users[x] = theme.palette.augmentColor({ color: colors[i] as ColorPartial }));

        setData(data);
        setWeeks(weeks);
        setError(undefined);

    }, [view, loadTimestamp])

    if (error) return <Alert severity='error'>{error}</Alert>
    if (!weeks) return null

    const props = {
        data: data!,
        onClick
    };

    return (
        <Box
            sx={{
                overflow: "auto",
                height: compact ? "100%" : "calc(100% - 64px)",
                backgroundColor: 'white',
            }}
        >
            <Stack spacing={1} className={css(styles.container)}>
                {
                    weeks.map(x => <Week {...props} key={x.format()} start={x} />)
                }
            </Stack>
        </Box>
    );
}

const styles = StyleSheet.create({
    container: {
        maxWidth: '800px',
    },
    week: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    days: {
        width: '100%',
        paddingBottom: 12,
    },
    weekLabel: {
        width: '100%',
        paddingLeft: 64,
    },
    day: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16,
    },
    dayDate: {
        width: '64px',
        textAlign: 'center',
        flexShrink: 0,
    },
    appointments: {
        width: '100%',
        marginRight: 12,
    },
    card: {
        borderRadius: 12,
        padding: 12,
        width: '100%',
    },
    cardAppointment: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    cardEvent: {
        border: '1px solid',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
});
